export interface clientFleetDto {
  companyFleetNo: number;
  fleetName: string;
  fleetColor: string;
  latitude: number;
  longitude: number;
  fullAddress: string;
  province_ID: number;
}
export class TruckFleet {
  fleetName: string;
  fleetId: number;
  fleetTruckSum: number;
  fleetDriverSum: number;
  truckList: TruckFleetItem[] = [];
  driverList: DriverFleetItem[] = [];
  fleetColor: string;
}

export class TruckFleetItem {
  truckTypeName: string;
  truckTypeId: number;
  truckTypeImage: string;
  trucTypeCount: number;
}

export class DriverFleetItem {
  driverName: string;
  driverId: number;
  driverImage: string;
  driverCount: number;
}

export class FleetDriver {
  photo: string;
  user_Name: string;
  user_ID: number;
}

export class TruckTypeGroupDto {
  id: number;
  name: string;
  detail: string;
  photo: string;
  truckTypeList: TruckTypeDto[];
}

export class TruckTypeDto {
  id: number;
  name: string;
  truckTypeName: string;
  displyName: string;
  photo: string;
  maxWeight: number | null;
  maxPallet: number | null;
  truckTypeGroupID: number | null;
  isSelect: boolean;
  isEdit: boolean;
  numberOfTruck: number;
  isNew: boolean;
  numberOfHasLicense: number;
  companyTruckTypeDto: CompanyTruckTypeDto | null;

  totalMaxWeight: number | null;
  deliveryMaxWeight: number | null;
  headTypeId: number | null;
  tailTypeId: number | null;
  pricingMethod: number;
  width: number | null;
  length: number | null;
  height: number | null;
  fuelUseRate: number | null;
  haveTail: boolean;
  haveHeadCapacity: boolean;
  valid: boolean;
  truckTypeGroup_ID: number | null;
  defaultHeadMaxWeight: number | null;
  defaultHeadMaxPallet: number | null;
  defaultHeadWidth: number | null;
  defaultHeadLength: number | null;
  defaultHeadHeight: number | null;
}
export interface SaveAndUpdateCompanyTruckTypeCommand {
  comId: number | any;
  truckTypeGroupList: TruckTypeGroupDto[];
}

export interface AddCompanyTruckByTruckTypeIdCommand {
  comId: number | any;
  truckTypeId: number | any;
}
export interface CompanyTruckTypeDto {
  id: number;
  name: string;
  truckType_ID: number;
  companyService_ID: number;
  numberOfTruck: number;
  maxWeight: number | null;
  insurance: number | null;
  truckType: TruckTypeDto;
}

export class TruckAddToFleetDto {
  id: number;
  headLicense: string;
  headProvince: string;
  tailLicense: string;
  tailProvince: string;
  truckTypeId: number;
}

export interface FleetTruckType {
  truckType_ID: number;
  truckType_Name: string;
  photo: string;
  truckType_Licensed_Count: number;
  truckType_Non_Licensed_Count: number;
  haveTail: boolean;
}

export interface FleetDriver {
  user_ID: number;
  user_Name: string;
  photo: string;
}

export interface FleetItemOverView {
  fleet_ID: number;
  fleet_No: number;
  fleet_Name: string;
  fleet_color: string;
  fleet_FullAddress: string;
  truck_Licensed_Count: number;
  truck_Non_Licensed_Count: number;
  driver_Count: number;
  fleetTruckTypes: FleetTruckType[];
  fleetDrivers: FleetDriver[];
}

export interface TruckLocationTracking {
  company_truck_id: number;
  latitude: number;
  longtitude: number;
  booking_id: number;
  shipment_tms_id: number;
  order_360_id: number;
  fleet_id: number;
  fleet_name: string;
  fleet_no: number;
  fleet_color: string;
  truck_photo: string;
  truck_status: string;
  isTruckOnWork: boolean;
  driver_name: string;
  driver_mobile_no: string;
  driver_photo: string;
  truckType_name: string;
  head_license: string;
  head_province: string;
  tail_license: string;
  tail_province: string;
  last_location_time: string;
  last_location: string;
}

export interface CompanyTruckTypeDtoWithPhoto {
  name: string;
  truckType_ID: number;
  companyService_ID: number;
  numberOfTruck: number;
  maxWeight: number;
  photo: string;
  companyTruckType_ID: number;
}

export interface SaveAndUpdateCompanyTruckTypeCommand {
  comId: number | any;
  truckTypeGroupList: TruckTypeGroupDto[];
}
