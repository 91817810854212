import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpResponse
} from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { catchError, map, retry, takeUntil } from 'rxjs/operators';
import { User } from '../_Models/UserModel/User';
import { LoginDto } from '../_Models/UserModel/Login';
import { environment } from 'src/environments/environment';
import {
  CookieCollectionModel,
  TSTResponseCookies,
} from '../_Models/TSTResponseCookies';
import { CryptoJsService } from './crypto-js.service';
import { HttpService } from './http.service';
import {
  AuthenticationAPIPath,
  ClientAPIPath,
} from '../_Constants/apiConstants';
import { TMSAPIResponse } from '../_Models/TMSAPIResponse';
import {
  ClientModule,
  Operation,
  PermissionModule,
} from '@app/core/_Models/UserModel/UserPermission';
import { PermissionGroup } from '../_Models/UserModel/PermissionGroup';
import * as _ from 'lodash';
import { CookieService } from 'ngx-cookie';
import { Router } from '@angular/router';
import { OAuthTokenRequest, OAuthTokenResponse } from '../_Models/oauth-token-dto';
declare const $: any;

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  public currentToken: BehaviorSubject<OAuthTokenResponse>;
  public photoAsync = new BehaviorSubject<string>(null);

  private currentUserPermissionSubject: BehaviorSubject<PermissionModule>;
  public currentUserPermission: Observable<PermissionModule>;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private http: HttpClient,
    private httpService: HttpService,
    private cryto: CryptoJsService,
    private _apiSerive: HttpService,
    private cookieService: CookieService,
    private router: Router,
  ) {
    let uData = localStorage.getItem('cu');
    let decrypt: any = null;
    if (uData) {
      try {
        decrypt = this.cryto.decrypt(uData);
      } catch {
        this.deleteAllCookies();
        localStorage.clear();
        sessionStorage.clear();
      }


      // try {
      //   decrypt = this.cryto.decrypt(uData);
      //   this.currentUserSubject = new BehaviorSubject<User>(decrypt);
      //   this.currentUser = this.currentUserSubject.asObservable();
      // }
      // catch {
      //   this.currentUserSubject = new BehaviorSubject<User>(null);
      //   this.currentUser = this.currentUserSubject.asObservable();
      //   this.logout();
      // }
    }
    this.currentUserSubject = new BehaviorSubject<User>(decrypt);
    this.currentUser = this.currentUserSubject.asObservable();
    this.currentToken = new BehaviorSubject(null);
  }

  public get currentUserValue(): User {
    return this.currentUserSubject?.value;
  }

  UserPermissionModule(moduleId: number): PermissionModule {
    const modulePermission = this.currentUserValue?.Permission?.modulePermissionDtos?.find(
      (f) => f.module.id == moduleId
    );
    return modulePermission?.module ?? null;
  }

  isSuperUser(): boolean {
    return (
      this.currentUserSubject && this.currentUserSubject.value.Role.roleID == 11
    );
  }

  isTruckPlusUser(): boolean {
    return (
      this.currentUserSubject &&
      this.currentUserSubject.value.clientModules.some((e) => e.amo_id == 50800)
    );
  }

  FindPermissionAction(id: number, operation: Operation[]): PermissionGroup {
    if (operation && operation.length > 0) {
      const mapper = operation
        .filter((f) => f.id === id)
        .map((m) => m.actionDto);
      if (mapper && mapper.length > 0) {
        const item = mapper[0];
        const p: PermissionGroup = {
          canRead: item.indexOf(1) !== -1,
          canPublish: item.indexOf(2) !== -1,
          canEdit: item.indexOf(3) !== -1,
          canDelete: item.indexOf(4) !== -1,
          canApprove: item.indexOf(5) !== -1,
        };
        return p;
      }
    }
    return {
      canRead: false,
      canPublish: false,
      canEdit: false,
      canDelete: false,
      canApprove: false,
    };
  }

  login(username: string, password: string, remember = false) {
    let identityUser = new LoginDto();
    identityUser.AppPackage = 3;
    identityUser.Pool = 1;
    identityUser.Username = username;
    identityUser.Password = password;
    identityUser.Remember = remember;
    return this.http
      .post<any>(
        `${environment.IdentityAPIUrl_V4}/auth/login/tms`,
        identityUser
      )
      .pipe(
        map(async (res) => {
          console.log('res IdentityAPIUrl_V4 ', res);
          if (res.isSuccess) {
            // Set User data after login success
            await this.SetUserData(res).then(
              async (res) => {
                // Fileserve 360TECHX
                await this.FilesServer360TechXCookiieCreate();
                //FileServer 360TRUCK
                await this.FilesServer360TruckCookiieCreate();
                const userData = res.tstUserModel;

                // const requestOAuth2 = {
                //   response_type: "code",
                //   redirect_uri: environment.IdentityV2CallbackUrl,
                //   state: '4121f636-0baa-4a92-98db-a11644f16b9d',
                //   scope: 'READ,WRITE',
                //   remember: true,
                //   clientUserId: res.tstS360_UserID,
                //   userToken: res?.access_token

                //   // clientId: res.ClientID
                // }
                // console.log('call oauth2', requestOAuth2);

                // const oauth2Response = await this.authorizeIdentityV2(requestOAuth2)
                // console.log('oauth2Response', oauth2Response);


              },
              (err) => {
                res.isSuccess = false;
                res.statusCode = 400;
                res.message = 'ไม่สามารถเข้าสู่ระบบได้...';
              }
            );
          }
          return res;
        }),
        catchError(this.handleError)
      );
  }

  // This login is use when use cookie login
  loginCheck() {
    return this.http
      .get<any>(
        `${environment.apiUrl}${AuthenticationAPIPath.UserDetail}`, { observe: 'response', withCredentials: true }
      ).pipe(
        map((res) => {
          // console.log('UserDetail: ', res);
          //  this.SetUserDataCookieLogin(res)
          return res;
        }),
        catchError(this.handleError)
      );
  }

  fakelogin(user: User) {
    localStorage.setItem('currentUser', JSON.stringify(user));
    this.currentUserSubject.next(user);
    return user;
  }

  CanUserThisMobileNo(mobileNo: string, authorizeId?: string, companyId?: string, userId?: number) {
    let userIdParameter = "";
    if (userId)
      userIdParameter = `&userId=${userId}`
    const headers = this.SetHttpHeader();
    return this.http
      .get(
        `${environment.IdentityAPIUrl_V3}${AuthenticationAPIPath.CanUseThisMobileNo}${mobileNo}&userPool=1&authorizationLevelID=${authorizeId}&companyId=${companyId}&defaultServiceType=${this.currentUserValue.registerPackage.serviceTypeId}${userIdParameter}`,
        { headers: headers }
      )
      .pipe(
        map((res) => {
          return res;
        }),
        retry(2),
        catchError(this.handleError)
      );
  }
  CanUserThisEmail(email: string) {
    const headers = this.SetHttpHeader();
    return this.http
      .get(
        `${environment.IdentityAPIUrl_V2}${AuthenticationAPIPath.CanUseThisEmail}${email}`,
        { headers: headers }
      )
      .pipe(
        map((res) => {
          console.log(res);

          return res;
        }),
        retry(2),
        catchError(this.handleError)
      );
  }

  SetHttpHeader(): any {
    let headers = new HttpHeaders({
      Authorization: environment.IdentityBearerToken,
      userPool: '1',
    });
    return headers;
  }

  async logout() {
    // $.cookie('my_cookie',null, {domain:'.360techx.co'});
    this.deleteAllCookies();
    localStorage.clear();
    sessionStorage.clear();
    this.currentUserSubject.next(null);
  }

  deleteAllCookies() {

    var iframes = document.querySelectorAll('iframe');
    for (var i = 0; i < iframes.length; i++) {
      iframes[i].parentNode.removeChild(iframes[i]);
    }
    const c = this.cookieService.getAll();
    console.log('cookie : ', c);
    this.cookieService.removeAll();
    let headers = new HttpHeaders({
      Authorization: environment.IdentityBearerToken,
    });
    this.http
      .get(environment.setting_AWS.Cloudfront.ClearCookie360THECX)
      .subscribe((res) => {
        console.log('FilesServer360TechXCookieClear: ', res);

      });
    this.http
      .get(environment.setting_AWS.Cloudfront.ClearCookie360TRUCK)
      .subscribe((res) => {
        console.log('FilesServer360TruckCookieClear: ', res);

      });
    let cookies = document.cookie.split(';');
    console.log('cookies : ', cookies);
    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i];
      let eqPos = cookie.indexOf('=');
      let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
      delete cookies[cookie];
    }
  }

  private handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = error.message;
    }
    // localStorage.setItem('stopl', 'true');
    return throwError(errorMessage);
  }

  private CreateCookie(cookieData: TSTResponseCookies) {
    cookieData.cookieCollection.forEach((item: CookieCollectionModel) => {
      const expUtcAt = new Date(cookieData.cookieOptions.expires);
      var now = new Date();
      var dif = expUtcAt.getTime() - now.getTime();
      var Seconds_from_T1_to_T2 = dif / 1000;
      const remain = Math.abs(Seconds_from_T1_to_T2);

      const cookie =
        item.name +
        '=' +
        item.value +
        '; ' +
        'max-age=' +
        remain +
        '; ' + // seconds // "max-age=" + (24*60*60) + ";" + // seconds --> 1 day
        'path=' +
        cookieData.cookieOptions.path +
        '; ' +
        'domain=' +
        cookieData.cookieOptions.domain +
        '; ' +
        (cookieData.cookieOptions.secure ? 'Secure; ' : '') +
        'SameSite=' +
        cookieData.cookieOptions.sameSite.toString();
      /// generate cookie
      document.cookie = "'" + cookie + "'";
    });
  }

  FilesServer360TechXCookiieCreate() {
    this.currentUser.subscribe((u) => {

      if (u) {
        u?.tstS360_UserID;
        let data = {
          userID: u.tstS360_UserID.toString(),
          companyID: u?.tstS360_CompanyID?.toString(),
          tmS_ClientID: u.ClientID.toString()
        };
        let headers = new HttpHeaders({
          Authorization: environment.IdentityBearerToken,
        });
        this.http
          .post(environment.setting_AWS.Cloudfront.SignatureKey, data, {
            headers: headers,
            responseType: 'text',
          })
          .subscribe((res) => {
            console.log('FilesServer360TechXCookiieCreate : ', res);
            var iframe = document.createElement('iframe');
            iframe.id = 'FilesServer360TechXCookiieCreate';
            iframe.src =
              environment.setting_AWS.Cloudfront.Fileserver360THECX +
              '&signature=' +
              encodeURI(res);
            iframe.style.display = 'none';
            document.body.appendChild(iframe);
          });
      }

    });
  }

  FilesServer360TruckCookiieCreate() {
    this.currentUser.subscribe((u) => {
      if (u) {
        u?.tstS360_UserID;
        let data = {
          userID: u.tstS360_UserID.toString(),
          companyID: u?.tstS360_CompanyID?.toString(),
          tmS_ClientID: u.ClientID.toString()
        };
        let headers = new HttpHeaders({
          Authorization: environment.IdentityBearerToken,
        });
        this.http
          .post(environment.setting_AWS.Cloudfront.TSTSignatureKey, data, {
            headers: headers,
            responseType: 'text',
          })
          .subscribe((res) => {
            var iframe = document.createElement('iframe');
            iframe.id = 'FilesServer360TruckCookiieCreate';
            iframe.src =
              environment.setting_AWS.Cloudfront.Fileserver360TRUCK +
              '?signature=' +
              encodeURI(res);
            iframe.style.display = 'none';
            document.body.appendChild(iframe);
          });
      }

    });
  }

  SetUserDataCookieLogin(res: any, userToken: string = ""): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const userData = _.mapKeys(JSON.parse(res.body.tstUserModel), (v, k) => _.camelCase(k));
      console.log('SetUserDataCookieLogin res', res);

      // const headers = new HttpHeaders({
      //   Authorization: 'Bearer ' + res?.tstUserJwt,
      // });
      const checkUrl = environment.apiUrl + ClientAPIPath.ClientGetCurrentUser;
      const userDataAPI = this.http
        .get(checkUrl, { withCredentials: true, observe: 'response' })
        .pipe(
          map((res) => {
            // console.log("res",res);
            return res;
          },
            //retry(2),
            catchError(this.handleError)
          ));

      userDataAPI.subscribe(async (UserResponse: any) => {
        if (UserResponse.status === 200) {
          console.log('ClientGetUser :', UserResponse);
          let user = new User();
          user.id = UserResponse.body?.tsT360_UserID;
          user.ClientID = UserResponse.body?.clientID;
          user.access_token = userToken;
          user.tstS360_CompanyID = UserResponse.body?.tsT360_CompanyID;
          user.tstS360_UserID = UserResponse.body?.tsT360_UserID;
          user.phoneNumber = UserResponse.body?.phoneNumber;
          user.email = UserResponse.body?.email;
          user.fullName = UserResponse.body?.fullName;
          user.firstName = UserResponse.body?.firstName;
          user.lastName = UserResponse.body?.lastName;
          user.photoURL = UserResponse.body?.photoURL;

          user.team = UserResponse.body?.teamDto;
          user.department = UserResponse.body?.departmentDto;
          user.Role = UserResponse.body?.roleDto;
          user.client = UserResponse.body?.clientDto;

          user.Permission = UserResponse.body?.permissionDto;
          user.clientModules = UserResponse.body?.clientModule || [];
          user.appPackageSubscriptionIdList = UserResponse.body?.appPackageSubscriptionIdList;
          user.registerPackage = UserResponse.body?.registerPackage;
          user.companyTypeId = UserResponse.body?.companyTypeId;
          console.log('res?.tstUserJwt', res?.tstUserJwt);
          console.log('UserResponse.body?.clientModule', UserResponse.body?.clientModule);
          // const json = this.parseJwt(res?.tstUserJwt);
          // if (json) {
          //   const clientModule = json?.ClientModule ? JSON.parse(json?.ClientModule) : [] as ClientModule[]
          //   user.clientModules = clientModule;
          // }
          if (!environment.production) {
            console.log('userData  :', user);
          }

          const encrypt = this.cryto.encrypt(user);
          localStorage.setItem('cu', encrypt);
          //FileServer 360TECHX
          this.FilesServer360TechXCookiieCreate();
          //FileServer 360TRUCK
          this.FilesServer360TruckCookiieCreate();
          this.currentUserSubject.next(user);

          // const requestOAuth2 = {
          //   response_type: "code",
          //   redirect_uri: environment.IdentityV2CallbackUrl,
          //   state: '4121f636-0baa-4a92-98db-a11644f16b9d',
          //   scope: 'READ,WRITE',
          //   remember: true,
          //   clientUserId: user.tstS360_UserID,
          //   userToken: res?.tstUserJwt
          //   // clientId: res.ClientID
          // }
          // console.log('call oauth2');

          // const oauth2Response = await this.authorizeIdentityV2(requestOAuth2)
          // console.log('oauth2Response', oauth2Response);

          resolve(user);
        } else {
          reject(UserResponse.message);
        }
      });
    });

  }

  private SetUserData(res: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      // let user2 = new User();
      // user2.access_token = res?.tstUserJwt;
      // this.currentUserSubject.next(user2);
      console.log("Setdata res", res)
      console.log("Setdata res?.tstUserJwt", res?.tstUserJwt)

      const userData = res.tstUserModel;
      const headers = new HttpHeaders({
        Authorization: 'Bearer ' + res?.tstUserJwt,
      });
      const userDataAPI = this.http
        .get(
          environment.apiUrl +
          ClientAPIPath.ClientGetUser +
          userData?.tsT360_UserID, { headers: headers, withCredentials: true }
        )
        .pipe(
          map((res) => {
            console.log("res", res);
            return res;
          }),
          retry(2),
          catchError(this.handleError)
        );

      userDataAPI.subscribe((UserResponse: TMSAPIResponse) => {
        
        if (UserResponse.isSuccess) {
          console.log('ClientGetUser :', UserResponse);
          let user = new User();
          user.id = userData?.tsT360_UserID;
          user.ClientID = UserResponse.data?.clientID;
          user.access_token = res?.tstUserJwt;
          user.tstS360_CompanyID = UserResponse.data?.tsT360_CompanyID;
          user.tstS360_UserID = UserResponse.data?.tsT360_UserID;
          user.phoneNumber = UserResponse.data?.phoneNumber;
          user.email = UserResponse.data?.email;
          user.fullName = UserResponse.data?.fullName;
          user.firstName = UserResponse.data?.firstName;
          user.lastName = UserResponse.data?.lastName;
          user.photoURL = UserResponse.data?.photoURL;

          user.team = UserResponse.data?.teamDto;
          user.department = UserResponse.data?.departmentDto;
          user.Role = UserResponse.data?.roleDto;
          user.client = UserResponse.data?.clientDto;

          user.Permission = UserResponse.data?.permissionDto;
          const json = this.parseJwt(res?.tstUserJwt);
          if (json) {
            const clientModule = json?.ClientModule ? JSON.parse(json?.ClientModule) : [] as ClientModule[]
            user.clientModules = clientModule;
          }
          user.clientModules = UserResponse.data?.clientModule || [];
          user.appPackageSubscriptionIdList = UserResponse.data?.appPackageSubscriptionIdList;
          user.registerPackage = UserResponse.data?.registerPackage;
          user.companyTypeId = UserResponse.data?.companyTypeId;

          if (!environment.production) {
            console.log('userData  :', user);
          }

          if (user.Role.roleID == 9) {
            this.logout();
            this.router.navigate(['/login']);
          }

          const encrypt = this.cryto.encrypt(user);
          localStorage.setItem('cu', encrypt);
          this.currentUserSubject.next(user);
          resolve(user);
        } else {
          reject(UserResponse.message);
        }
      });
    });
  }

  getProfilePhotoByServer(userId: number): Observable<any> {
    return this._apiSerive.get(`/ClientUser/get-profile-image?userId=${userId}`);
  }

  parseJwt(token): any {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
  };

  async authorizeIdentityV2(request) {
    const params = new URLSearchParams(request).toString();

    const response = await this._apiSerive.postWithHeader(environment.IdentityV2Url, request, {
      'Content-Type': 'application/json'
    }).toPromise().then(async (res) => {
      await console.log('authorizeIdentityV2', res);
      return res;
    })
    if (response.status) {
      if (response.code && response.redirect_uri) {
        this._apiSerive
        const result = await this._apiSerive.getWithHeader(response.redirect_uri + '&redirect_uri=' + environment.webBasic + '/booking', { withCredentials: true }).toPromise().then(async (res) => {
          await console.log('auth callback v2', res);
          return res;
        });
        console.log(result);


        // window.location.href = response.redirect_uri
      }
    }


  }

  callbackOAuth2(code: string, redirect: string): Promise<TMSAPIResponse> {
    return new Promise((resolve, reject) => {
      this.http.get(`${environment.apiUrl}/v3/auth/callback?code=${code}&redirect=${redirect}`)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res: TMSAPIResponse) => {
          console.log('get /v3/auth/callback', res);
          resolve(res);
        });
    })
  }

  getTokenOAuth2(code: string, state: string, grant_type: string = "authorization_code"): Promise<OAuthTokenResponse> {
    const reqeust = {
      code,
      state,
      grant_type: grant_type,
      redirect_uri: environment.web,
      client_id: environment.oauth.client_id,
      client_secret: environment.oauth.client_secret,
    } as OAuthTokenRequest
    const body = new URLSearchParams();
    if (grant_type == "authorization_code") {
      body.set('code', code);
    } else if (grant_type == "refresh_token") {
      body.set('refresh_token', localStorage.getItem('rt') ?? "");
    }
    body.set('state', state);
    body.set('grant_type', grant_type);
    body.set('redirect_uri', `${environment.web}/cookie-login`);
    body.set('client_id', environment.oauth.client_id);
    body.set('client_secret', environment.oauth.client_secret);

    console.log('getTokenOAuth2 reqeust', reqeust);

    return new Promise((resolve, reject) => {
      this.http.post(`${environment.IdentityAPIUrl_V6}/oauth2/token`, body.toString(),
        {
          headers:
            { 'Content-Type': 'application/x-www-form-urlencoded', Accept: 'application/json' }
        }
      )
        .pipe(takeUntil(this.destroy$))
        .subscribe((res: OAuthTokenResponse) => {
          console.log('get /auth2/token', res);
          if (res.error_description) {
            window.location.href = '/logout'
          }
          localStorage.setItem('at', res.access_token);
          localStorage.setItem('rt', res.refresh_token);
          this.currentToken.next(res);
          resolve(res);
        });
    })
  }
}
