import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {
  CompanyTruckType,
  CompanyTruckTypeApiPath,
  TruckApiPath,
} from '@app/core/_Constants/apiConstants';
import { TMSAPIResponse } from '@app/core/_Models/TMSAPIResponse';
import {
  AuthenticationService,
  HttpService,
  NotificationService,
  PageloadingService,
} from '@app/core/_Services';
import {
  FleetItemOverView,
  TruckTypeDto,
  TruckTypeGroupDto,
} from '@app/modules/truck-management/models/TruckFleet';
import { TruckManagementService } from '@app/modules/truck-management/service/truck-management.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  CompanyTruckDto,
  ITruckImportModel,
  ITruckImportModelResponse,
  TruckImportHeaders,
} from '../models/company-truck-model';
import * as XLSX from 'xlsx';
import { ProvinceService } from './master-data.service';
import { ProvinceDto } from '@app/core/_Models/MasterModel/ProvinceModel';
import { InsuranceCompanyService } from './insurance-company.service';
import { InsuranceCompanyModel } from '@app/core/_Models/MasterModel/InsuranceCompanyModel';
import { ShipmentTruckModel } from '@app/modules/booking/models/2022/shipment-model';

@Injectable({
  providedIn: 'root',
})
export class CompanyTruckService {
  destroy$: Subject<boolean> = new Subject<boolean>();

  companyTruckTypeListOnChange: BehaviorSubject<TruckTypeGroupDto[]>;
  companyTruckListOnChange: BehaviorSubject<CompanyTruckDto[]>;
  companyTruckItemOnChange: BehaviorSubject<CompanyTruckDto>;
  companyTruckFleetListOnChange: BehaviorSubject<FleetItemOverView[]>;
  truckDataVerifyedListOnChange: BehaviorSubject<ITruckImportModelResponse[]>;
  shipmentTruckListOnChange: BehaviorSubject<ShipmentTruckModel[]>;
  shipmentTruckListOnChangeForAssign: BehaviorSubject<CompanyTruckDto[]>;

  insureCompanyList: InsuranceCompanyModel[] = [];
  provinceList: ProvinceDto[] = [];
  truckTypeList: TruckTypeDto[] = [];

  constructor(
    private apiService: HttpService,
    private truckManagementService: TruckManagementService,
    // private pageload: PageloadingService,
    private authen: AuthenticationService,
    private masterService: ProvinceService,
    private insureCompanyService: InsuranceCompanyService,
    private _noti: NotificationService
  ) {
    this.companyTruckTypeListOnChange = new BehaviorSubject(
      new Array<TruckTypeGroupDto>()
    );
    this.companyTruckListOnChange = new BehaviorSubject(
      new Array<CompanyTruckDto>()
    );
    this.companyTruckFleetListOnChange = new BehaviorSubject(
      new Array<FleetItemOverView>()
    );
    this.truckDataVerifyedListOnChange = new BehaviorSubject(
      new Array<ITruckImportModelResponse>()
    );
    this.companyTruckItemOnChange = new BehaviorSubject(null);
    this.shipmentTruckListOnChange = new BehaviorSubject(
      new Array<ShipmentTruckModel>()
    );
    this.shipmentTruckListOnChangeForAssign = new BehaviorSubject(
      new Array<CompanyTruckDto>()
    );
  }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    const { queryParams, params } = route;
    return new Promise((resolve, reject) => {
      Promise.all([this.getFleetOverAll()]).then(() => {
        //this.pageload.hide();
        resolve(true);
      }, reject);
    });
  }

  getFleetOverAll(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.truckManagementService
        .GetFleetOverAll()
        .pipe(takeUntil(this.destroy$))
        .subscribe((res: TMSAPIResponse) => {
          this.companyTruckFleetListOnChange.next(res.data);
          resolve(res.data);
        });
    });
  }

  getCompanyTruckList(dataReq: any): Promise<CompanyTruckDto[]> {
    return new Promise((resolve, reject) => {
      this.apiService
        .post(TruckApiPath.GetCompanyTruckList, dataReq)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res: TMSAPIResponse) => {
          this.companyTruckListOnChange.next(res.data);
          resolve(res.data);
        });
    });
  }

  getCompanyTruckById(companyTruckId: number): Promise<CompanyTruckDto> {
    let companyId = this.authen.currentUserValue?.tstS360_CompanyID;
    return new Promise((resolve, reject) => {
      this.apiService
        .post(TruckApiPath.GetCompanyTruckById, {
          companyTruckId: companyTruckId,
          companyId: companyId,
        })
        .pipe(takeUntil(this.destroy$))
        .subscribe((res: TMSAPIResponse) => {
          this.companyTruckItemOnChange.next(res.data);
          resolve(res.data);
        });
    });
  }

  saveAndUpdateCompanyTruck(dataReq: any): Promise<TMSAPIResponse> {
    return new Promise((resolve, reject) => {
      this.apiService
        .post(TruckApiPath.CompanyTruck, dataReq)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res: TMSAPIResponse) => {
          // this.companyTruckItemOnChange.next(res.data);
          if (res.isSuccess) {
            this._noti.toastsuccess('', 'บันทึกรายการรถบรรทุกสำเร็จ');
          }
          resolve(res);
        });
    });
  }

  deleteCompanyTruck(dataReq: any): Promise<TMSAPIResponse> {
    return new Promise((resolve, reject) => {
      this.apiService
        .patch(TruckApiPath.DeleteCompanyTruck, dataReq)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res: TMSAPIResponse) => {
          resolve(res);
        });
    });
  }

  // companyTruckType
  getCompanyTruckTypeList(): Promise<CompanyTruckDto[]> {
    return new Promise((resolve, reject) => {
      let companyId = 0;
      this.authen.currentUser
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => (companyId = res.tstS360_CompanyID));
      setTimeout(() => {
        this.apiService
          .get(
            `${CompanyTruckTypeApiPath.CompanyTruckType}?companyId=${companyId}`
          )
          .pipe(takeUntil(this.destroy$))
          .subscribe((res: TMSAPIResponse) => {
            this.companyTruckTypeListOnChange.next(res.data);
            resolve(res.data);
          });
      }, 1);
    });
  }

  getCompanyTruckTypeListByCompanyTruckID(comTruckTypeList): Observable<any> {
    return this.apiService.post(
      TruckApiPath.GetCompanyTruckByCompanyTruckTypeId,
      { companyTruckTypeList: comTruckTypeList }
    );
  }

  getCompanyTruckWithShipment(dataReq: any): Promise<ShipmentTruckModel[]> {
    return new Promise((resolve, reject) => {
      this.apiService
        .post(TruckApiPath.GetCompanyTruckWithShipment, dataReq)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res: TMSAPIResponse) => {
          this.shipmentTruckListOnChange.next(res.data);
          resolve(res.data);
        });
    });
  }

  importTruck(file: File): Promise<TMSAPIResponse> {
    //this.pageload.show();
    let truckDataImports: ITruckImportModel[] = [];
    return this.getDataFile(file).then((data) => {
      truckDataImports = data;
      return new Promise((resolve, reject) => {
        if (truckDataImports.length == 0) {
          resolve({
            isSuccess: false,
            statusCode: 400,
            message:
              'ขออภัยค่ะ ไม่สามารถทำรายการได้ เนื่องจากข้อมูลไม่ครบถ้วนโปรดตรวจสอบไฟล์ Excel และทำการอัปโหลดใหม่อีกครั้ง ขอบคุณค่ะ',
            errorDetail: '',
            data: [],
            dataTotalCount: 0,
          });
        } else if (truckDataImports.length > 200) {
          resolve({
            isSuccess: false,
            statusCode: 400,
            message: 'ขออภัยค่ะ สามารถทำรายการได้สูงสุด 200 คันต่อ 1 ไฟล์',
            errorDetail: '',
            data: [],
            dataTotalCount: 0,
          });
        }
        let companyId = 0;
        this.authen.currentUser
          .pipe(takeUntil(this.destroy$))
          .subscribe((res) => (companyId = res.tstS360_CompanyID));
        setTimeout(() => {
          this.apiService
            .post(TruckApiPath.VerifyTruckDataImport, {
              companyId: companyId,
              truckDataImports: truckDataImports,
            })
            .pipe(takeUntil(this.destroy$))
            .subscribe((res: TMSAPIResponse) => {
              this.truckDataVerifyedListOnChange.next(res.data);
              //this.pageload.hide();
              resolve(res);
            });
        }, 10);
      });
    });
  }

  getDataFile(file: File): Promise<ITruckImportModel[]> {
    const keys = TruckImportHeaders;
    return new Promise((resolve, reject) => {
      const reader: FileReader = new FileReader();
      reader.onload = (e: any) => {
        const binarystr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(binarystr, {
          type: 'binary',
          cellText: true,
          cellDates: true,
        });
        // console.log('wb.Workbook.WBProps.date1904',wb.Workbook.WBProps.date1904);

        // wb.Workbook.WBProps.date1904
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];
        let data = XLSX.utils.sheet_to_json(ws, {
          range: 2,
          header: keys,
          raw: false,
          dateNF: 14,
        }) as ITruckImportModel[];
        const rowIndex = 3;
        data = data.map((e, index) => {
          return {
            ...e,
            headLicenseExpireDate: this.parseDate(e.headLicenseExpireDate),
            tailLicenseExpireDate: this.parseDate(e.tailLicenseExpireDate),
            insureCarExpireDate: this.parseDate(e.insureCarExpireDate),
            insureCargoExpireDate: this.parseDate(e.insureCargoExpireDate),
            rowIndex: rowIndex + index,
          };
        });
        resolve(data);
      };
      reader.onerror = reject;
      reader.readAsBinaryString(file);
    });
  }

  private parseDate(date: string): string {
    const DELIMITER = '/';
    if (date) {
      let newDate = date.split(DELIMITER);
      if (newDate.length <= 2) {
        return `${newDate[1]}-${newDate[0]}-01`;
      } else {
        newDate[2] = newDate[2].length <= 2 ? '25' + newDate[2] : newDate[2];
        if (Number(newDate[1]) > 12) {
          return `${newDate[2]}-${newDate[0]}-${newDate[1]}`;
        }
        return `${newDate[2]}-${newDate[1]}-${newDate[0]}`;
      }
    }
    return null;
  }

  saveCompanyTruckDataImport(
    dataReq: ITruckImportModelResponse[]
  ): Promise<TMSAPIResponse> {
    //this.pageload.show();
    return new Promise((resolve, reject) => {
      let companyId = 0;
      let actionBy = 0;
      this.authen.currentUser
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          companyId = res.tstS360_CompanyID;
          actionBy = res.id;
        });
      setTimeout(() => {
        this.apiService
          .post(TruckApiPath.SaveCompanyTruckDataImport, {
            companyId: companyId,
            actionBy: actionBy,
            truckDataImports: dataReq,
          })
          .pipe(takeUntil(this.destroy$))
          .subscribe((res: TMSAPIResponse) => {
            this.truckDataVerifyedListOnChange.next(res.data);
            //this.pageload.hide();
            if (res.isSuccess) {
              this._noti.toastsuccess('', 'เพิ่มรถบรรทุกสำเร็จ');
            }
            resolve(res);
          });
      }, 1);
    });
  }

  saveDefaultDriver(
    driverIds: number[],
    comTruckId: number
  ): Promise<TMSAPIResponse> {
    return new Promise((resolve, reject) => {
      this.apiService
        .patch(TruckApiPath.SaveDefaultDriver, {
          userId: this.authen.currentUserValue.tstS360_UserID,
          driverId: driverIds[0],
          companyTruckId: comTruckId,
          DefaultDriverIDList: driverIds,
        })
        .pipe(takeUntil(this.destroy$))
        .subscribe((res: TMSAPIResponse) => {
          resolve(res);
        });
    });
  }

  GetCompanyTruckListByShipmentId(
    shipmentId: number,
    companyId: number
  ): Promise<TMSAPIResponse> {
    return new Promise((resolve, reject) => {
      this.apiService
        .post(TruckApiPath.GetCompanyTruckListByShipmentId, {
          // userId: this.authen.currentUserValue.tstS360_UserID,
          shipmentId: shipmentId,
          companyId: companyId,
        })
        .pipe(takeUntil(this.destroy$))
        .subscribe((res: TMSAPIResponse) => {
          this.shipmentTruckListOnChangeForAssign.next(res.data);
          //resolve(res);
        });
    });
  }
}
