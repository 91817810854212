import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { LocationModel } from './googlemap/Model/LoactionModel';
import { LocationData } from './googlemap/Model/LocationDataModel';
import { GoolemapService } from './googlemap/Services/goolemap.service';
import { ProvinceGroupModel } from './googlemap/Model/ProvinceGroupModel';
import GeocoderResult = google.maps.GeocoderResult;
import { BehaviorSubject, Subscription } from 'rxjs';
import { NotificationService, PageloadingService } from '@app/core/_Services';
import { debounceTime, distinctUntilChanged, mergeMap } from 'rxjs/operators';
import { RouteService } from '@app/core/_Services/route.service';

declare function openModal(modalID): any;
declare function closeModal(modalID): any;

class Coordenate {
  latitude: number;
  longitude: number;
}

class Position {
  coords: Coordenate;
}

@Component({
  selector: 'app-googlemap',
  templateUrl: './googlemap.component.html',
  styleUrls: ['./googlemap.component.scss'],
})
export class googlemapComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() FavoriteShow = false;
  @Input() IsMaster = false;
  @Input() InputText = 'สถานที่';
  @Input() MapModal = 'modalGooglemap';
  @Input() index?: number = null;
  @Input() valid = true;
  @Input() autoAddLocation = true;
  @Input() customerId: any = null;
  @Input() Data: LocationData = new LocationData();
  @Input() ReadOnly: boolean = false
  @Output() LocData: EventEmitter<LocationModel>;
  @Output() LocDataByIndex: EventEmitter<any>;
  @Output() locError: boolean = false;
  @ViewChild('searchinput') public searchinputEleRef: ElementRef;
  @ViewChild('mapView') public mapViewElementRef: ElementRef;

  loadingLocation = false;
  subscriptionSearch: Subscription;
  subscriptionKeySearch: Subscription;

  // init map
  isMasterViewMap = false;
  isLoadMap = true;
  position: LocationModel;
  zoom = 9;
  searchText: string;
  searchTextEvent = new BehaviorSubject<string>(null);
  LocationList: LocationData[];
  customerList: any;
  isCustomer: boolean = true;
  // map value
  // private locationData: BehaviorSubject<LocationData>;

  tmpLocationData: LocationData = new LocationData();

  constructor(
    private _mapService: GoolemapService,
    // private _loading: PageloadingService,
    private _noti: NotificationService,
    private detech: ChangeDetectorRef,
     private routeService: RouteService,
  ) {
    this.LocData = new EventEmitter();
    this.LocDataByIndex = new EventEmitter();
  }

  ngOnDestroy(): void {
    try {
      this.subscriptionSearch.unsubscribe();
    } catch (e) { }
    try {
      this.subscriptionKeySearch.unsubscribe();
    } catch (e) { }
  }

  ngOnInit(): void {
    this.subscriptionKeySearch = this.searchTextEvent
      .pipe(debounceTime(1000))
      .subscribe(() => {
        this.MapSearch();
      });

      this.routeService.isReloadFrom.subscribe((res) => {
        if (res) {
          this.Data = new LocationData();
        }
      });
  
  }

  async GetMasterLocation(): Promise<void> {
    // this._loading.show();
    this.LocationList = [];
    this.loadingLocation = true;
    try {
      const result = await this._mapService.GetClientLocation();
      // this._loading.hide();
      this.loadingLocation = false;
      if (result.isSuccess) {
        this.LocationList = result.data;
      }
    } catch (e) {
      // this._loading.hide();
      this.loadingLocation = false;
    }
  }

  updateKeyText(): void {
    console.log('searchText', this.searchText);
    this.searchTextEvent.next(this.searchText);
  }

  MapSearch() {
    try {
      this.subscriptionSearch.unsubscribe();
    } catch (e) { }
    if (this.searchText != '' && this.searchText) {
      // this._loading.show();
      this.LocationList = [];
      this.loadingLocation = true;
      this.subscriptionSearch = this._mapService
        .SearchLocation(this.searchText)
        .pipe(debounceTime(1500))
        .pipe(distinctUntilChanged())
        .subscribe(
          (result) => {
            // this._loading.hide();
            this.loadingLocation = false;
            if (result.isSuccess) {
              this.LocationList = result.data;
            }
          },
          (err) => {
            // this._loading.hide();
            this.loadingLocation = false;
          }
        );
    } else {
      this.GetMasterLocation();
    }
  }
  onMasterSelected(item: LocationData): void {
    this.tmpLocationData = item;
    // this.position = item;
    this.Data.contactName = item.contactName ;
    this.Data.contactPhone = item.contactPhone ;
  }

  async ngAfterViewInit(): Promise<void> {
    if (!this.IsMaster) {
      this.position = new LocationModel();
      // ดึง location ปัจจุบัน ของผู้ใช้
      //await this.CurrentPosition();
      // ประกาศ แผนที่ เริ่มต้น
      this.initMap();
      this.isLoadMap = false;
      this.detech.detectChanges();

      // ตั้ง input ให้เป็น autocomplete ของ google map
      this.initGooglemapAutoComplete();
    }
  }

  async GetCustomerList() {
    this.customerList = await this._mapService.GetMapCustomerList();
  }

  // Initialize and add the map
  initMap(): void {
    let uluru = { lat: this.position.lat, lng: this.position.lng };
    const map = new google.maps.Map(this.mapViewElementRef.nativeElement, {
      zoom: this.zoom,
      center: uluru,
    });

    // ตั้งจุด mark บนแผนที่
    this.SetMarker(map, uluru);
  }

  SetMarker(map: any, position: any): void {
    // The marker, positioned at Uluru
    const marker = new google.maps.Marker({
      draggable: false,
    });
    marker.setPosition(position);
    marker.setMap(map);
    google.maps.event.addListener(map, 'click', async (event) => {
      marker.setPosition(event.latLng);

      const result = await this.geocoderService(event.latLng);
      this.position.lat = result.geometry.location.lat();
      this.position.lng = result.geometry.location.lng();
      this.zoom = 14;
      // set input search
      this.searchinputEleRef.nativeElement.value = result.formatted_address;
      // set data
      this.tmpLocationData.lat = result.geometry.location.lat();
      this.tmpLocationData.lng = result.geometry.location.lng();
      this.tmpLocationData.shortAddress = result.formatted_address;
      this.tmpLocationData.fullAddress = result.formatted_address;
      this.tmpLocationData.placeId = result.place_id;
     var country = this.addressDeatilHelper(
        'country',
        result.address_components
      ).replace(' ', '');
      this.tmpLocationData.province = this.addressDeatilHelper(
        'province',
        result.address_components
      ).replace(' ', '');
      this.tmpLocationData.district = this.addressDeatilHelper(
        'ampur',
        result.address_components
      ).replace(' ', '');
      this.tmpLocationData.subDistrict = this.addressDeatilHelper(
        'tumbon',
        result.address_components
      ).replace(' ', '');

      console.log("country "+ country  +", pv -> " +this.tmpLocationData.province + ', aumper ' + this.tmpLocationData.district + ' ,tumbon ' + this.tmpLocationData.subDistrict)
      this.tmpLocationData.shortAddress = await this.placeService(
        map,
        result.place_id
      );
    });
  }

  placeService(map: any, place_id): Promise<string> {
    const service = new google.maps.places.PlacesService(map);
    const request = {
      placeId: place_id,
      fields: ['name'],
    };
    return new Promise<string>((resolve, reject) => {
      service.getDetails(request, function (place, status) {
        if (status == google.maps.places.PlacesServiceStatus.OK) {
          resolve(place.name);
        }
      });
    });
  }
  async searchWithTextFn(event) {
    this.locError = false
    if (event.key === 'Enter') {
      const txt = this.searchinputEleRef.nativeElement.value;

      const result = await this.geocoderService(null, txt);
      this.position.lat = result.geometry.location.lat();
      this.position.lng = result.geometry.location.lng();
      this.zoom = 14;
      this.searchinputEleRef.nativeElement.value = result.formatted_address;
      this.tmpLocationData.lat = result.geometry.location.lat();
      this.tmpLocationData.lng = result.geometry.location.lng();
      this.tmpLocationData.shortAddress = result.formatted_address;
      this.tmpLocationData.fullAddress = result.formatted_address;
      this.tmpLocationData.placeId = result.place_id;
      this.tmpLocationData.province = this.addressDeatilHelper(
        'province',
        result.address_components
      ).replace(' ', '');
      this.tmpLocationData.district = this.addressDeatilHelper(
        'ampur',
        result.address_components
      ).replace(' ', '');
      this.tmpLocationData.subDistrict = this.addressDeatilHelper(
        'tumbon',
        result.address_components
      ).replace(' ', '');

      const map = new google.maps.Map(this.mapViewElementRef.nativeElement, {
        zoom: this.zoom,
        center: this.position,
      });
      this.tmpLocationData.shortAddress = await this.placeService(
        map,
        result.place_id
      );
      this.SetMarker(map, this.position);
    }
  }
  geocoderService(
    latlng: any,
    textSearch: string = null
  ): Promise<GeocoderResult> {
    let geocoder = new google.maps.Geocoder();
    let zeroResult = false;
    return new Promise((resolve, reject) => {
      let request: any;
      if (textSearch) {
        request = {
          address: textSearch,
          region: 'th',
        };
      } else {
        let latlngData = {
          lat: latlng.lat(),
          lng: latlng.lng(),
        };
        request = {
          location: latlngData,
          region: 'th',
        };
      }

      geocoder.geocode(request, async (results, status) => {
        if (status === 'OK') {
          resolve(results[0]);
        } else {
          alert(
            'Geocode was not successful for the following reason: ' + status
          );
          resolve(null);
        }
      });
    });
  }

  initGooglemapAutoComplete(): void {
    const autocomplete = new google.maps.places.Autocomplete(
      this.searchinputEleRef.nativeElement
    );

    autocomplete.setFields([
      'address_components',
      'formatted_address',
      'place_id',
      'geometry',
      'name',
    ]);
    autocomplete.addListener('place_changed', () => {
      const place: google.maps.places.PlaceResult = autocomplete.getPlace();

      if (place.geometry === undefined || place.geometry === null) {
        return;
      }

      this.position.lat = place.geometry.location.lat();
      this.position.lng = place.geometry.location.lng();
      this.zoom = 14;

      // set data
      this.tmpLocationData.lat = place.geometry.location.lat();
      this.tmpLocationData.lng = place.geometry.location.lng();
      this.tmpLocationData.shortAddress = place.name;
      this.tmpLocationData.fullAddress = place.formatted_address;
      this.tmpLocationData.placeId = place.place_id;
      this.tmpLocationData.province = this.addressDeatilHelper(
        'province',
        place.address_components
      ).replace(' ', '');

      let addressDistrict = this.addressDeatilHelper('ampur', place.address_components);
      let addressSubDistrict = this.addressDeatilHelper('ampur', place.address_components);
      if (addressDistrict) {
        this.tmpLocationData.district = addressDistrict.replace(' ', '');
      }
      if (addressSubDistrict) {
        this.tmpLocationData.subDistrict = addressSubDistrict.replace(' ', '');
      }


      // this.locationData.next(data);
      this.initMap();
    });

    this.detech.detectChanges();
  }

  CurrentPosition(): Promise<LocationModel> {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (pos: Position) => {
            if (pos) {
              this.position.lat = pos.coords.latitude;
              this.position.lng = pos.coords.longitude;
            }
            resolve(this.position);
          },
          (err) => {
            console.error('getCurrentPosition', err);
            resolve(this.position);
          }
        );
      } else {
        resolve(this.position);
      }
    });
  }

  async onSubmit() {
    await this.returnLocData();
    closeModal('#' + this.MapModal);
  }

  async shortAddressChange() {
    await this.returnLocData();
  }

  async returnLocData() {
    if (this.tmpLocationData.placeId) {
      console.log(this.tmpLocationData)
      const province = this.tmpLocationData.province;
      const dist = this.tmpLocationData.district;
      const subDist = this.tmpLocationData.subDistrict;

      if (!this.tmpLocationData.clientLocationId) {
        const result = await this._mapService.ProvinceGroupGetDetail(
          province,
          dist,
          subDist
        );
        if (result.isSuccess) {
          let provinceGroup = new ProvinceGroupModel();
          provinceGroup = result.data;
          this.tmpLocationData.provinceId = provinceGroup.provinceId ? provinceGroup.provinceId :0;
          this.tmpLocationData.province = provinceGroup.provinceName;

          this.tmpLocationData.district = provinceGroup.districtName;
          this.tmpLocationData.districtId = provinceGroup.districtId ? provinceGroup.districtId :0;

          this.tmpLocationData.subDistrict = provinceGroup.subDistrictName;
          this.tmpLocationData.subDistrictId = provinceGroup.subDistrictId ? provinceGroup.subDistrictId :0;
          this.tmpLocationData.customerId = this.customerId;

          // Add new Client Location
          if (this.autoAddLocation) {
            const res = await this.AddNewClientLocation(this.tmpLocationData);
            if (!res) {
              return;
            }
          }
        } else {
          this._noti.toasterror('ผิดพลาด', result.message);
          return;
        }
      }
      this.Data = this.tmpLocationData;

      if (this.index === null) {
        this.LocData.emit(this.Data);
      } else {
        this.LocDataByIndex.emit({ data: this.Data, index: this.index });
      }
    }
  }

  async AddNewClientLocation(data): Promise<boolean> {
    const result = await this._mapService.AddNewClientLocation(data);
    if (result.isSuccess) {
      this.tmpLocationData = result.data;
    }
    return result.isSuccess;
  }

  async openmap(): Promise<void> {
    if (!this.IsMaster) {
      this.position = new LocationModel();
      this.tmpLocationData = new LocationData();
      this.Data = new LocationData();
      this.searchinputEleRef.nativeElement.value = null;
    }
    openModal('#' + this.MapModal);
    this.GetMasterLocation();
  }

  openmapWithValue(): void {
    console.log('test');

    if (this.Data.lat) {
      this.position = new LocationModel();
      this.position.lat = this.Data.lat;
      this.position.lng = this.Data.lng;
    }
    // this.initMap();
    this.isMasterViewMap = false;
    openModal('#' + this.MapModal);
    this.GetMasterLocation();
  }

  closemap(): void {
    closeModal('#' + this.MapModal);
  }

  async MasterViewMap(): Promise<void> {
    if (this.isMasterViewMap) {
      this.isMasterViewMap = false;
    } else {
      this.isMasterViewMap = true;

      this.position = new LocationModel();
      // ดึง location ปัจจุบัน ของผู้ใช้
      await this.CurrentPosition();
      // ประกาศ แผนที่ เริ่มต้น
      this.initMap();
      this.isLoadMap = false;

      // ตั้ง input ให้เป็น autocomplete ของ google map
      this.initGooglemapAutoComplete();

      await this.GetCustomerList();
      this.tmpLocationData.clientLocationId = null;
      this.tmpLocationData = new LocationData();
      console.log('tmpLocationData : ', this.tmpLocationData.shortAddress);
    }
  }

  addressDeatilHelper(type, address_components): string {
    this.locError = false;
    let tumbon_name = null;
    let ampur_name = null;
    let province_name = null;
    let zip_code = null;
    let country_name = null;
    let tumbon_index = 0;
    console.log(address_components)
    for (let i = 0; i < address_components.length; i++) {
      let ac = address_components[i];
      // console.log(ac)
      if (
        tumbon_index === 0 &&
        (ac.types.indexOf('locality') >= 0 ||
          ac.types.indexOf('sublocality_level_2') >= 0 ||
          ac.types.indexOf('sublocality') >= 0)
      ) {
        tumbon_name = ac.long_name;
        tumbon_index++;
      }

      if (
        ac.types.indexOf('administrative_area_level_2') >= 0 ||
        ac.types.indexOf('sublocality_level_1') >= 0 ||
        (ac.types.indexOf('locality') >= 0 )
      ) {
        ampur_name = ac.long_name;
      }

      if (ac.types.indexOf('administrative_area_level_1') >= 0) {
        province_name = ac.long_name;
      }

      if (ac.types.indexOf('natural_feature') >= 0) {
        this.locError = true
        // province_name = 'กรุงเทพมหานคร';
      }

      if (ac.types.indexOf('postal_code') >= 0) {
        zip_code = ac.long_name;
      }

      if (ac.types.indexOf('country') >= 0) {
        country_name = ac.long_name;
      }
    }

    if (type === 'tumbon' && tumbon_name != null) {
      return tumbon_name.replace(/ตำบล|แขวง|' '|/gi, (x) => {
        return (x = '');
      });
    } else if (type === 'ampur'  && ampur_name != null) {
      return ampur_name.replace(/อำเภอ|เขต|' '|/gi, (x) => {
        return (x = '');
      });
    } else if (type === 'province') {
      return province_name.replace(/จังหวัด|' '|/gi, (x) => {
        return (x = '');
      });
    } else if (type === 'zipcode') {
      return zip_code;
    } else if (type === 'country') {
      return country_name;
    } else {
      return '';
    }
  }
}
