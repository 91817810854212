<ng-template #modalPreviewImportData let-modal>
  <div class="modal-header" style="border-bottom: 1px solid #ccc">
    <div class="modal-title w-100">
      <button
        type="button"
        class="close pt-1 pl-0 pb-0 pr-1"
        aria-label="Close"
        data-dismiss="modal"
        (click)="close(false)"
      >
        <span aria-hidden="true"><i class="fal fa-times-circle"></i></span>
      </button>
      <div>
        <span class="h4">
          <i class="fas text-success" [ngClass]="dataModal?.iconHeader"></i>
          {{ dataModal?.title }}</span
        >
      </div>
    </div>
  </div>
  <div class="modal-body p-3">
    <div class="row">
      <div class="col-8">
        <span class="h5">รายการในไฟล์ทั้งหมด</span>
      </div>
      <div class="col-2">
        <span class="h5">{{
          dataModal?.dataPreviewList?.length | number
        }}</span>
      </div>
      <div class="col-2"><span class="h5">รายการ</span></div>
    </div>
    <div class="row">
      <div class="col-8">
        <span class="h5">รายการที่ผ่านการตรวจสอบ</span>
      </div>
      <div class="col-2 text-success">
        <span class="h5">{{ dataCorrectList.length | number }}</span>
      </div>
      <div class="col-2"><span class="h5">รายการ</span></div>
    </div>
    <div class="row">
      <div class="col-8">
        <span class="h5">รายการที่ไม่ผ่านการตรวจสอบ</span>
      </div>
      <div class="col-2 text-danger">
        <span class="h5">{{ dataIncorrectList.length | number }}</span>
      </div>
      <div class="col-2"><span class="h5">รายการ</span></div>
    </div>

    <div class="row">
      <div class="col-12">
        <!-- table -->
        <app-data-import-incorrect-list
          [dataIncorrectList]="dataIncorrectList || []"
        ></app-data-import-incorrect-list>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <span class="h5">หมายเหตุ :</span><br />
        <div *ngIf="!isNotAllowSubmitDataHasIncorrect">
          <span class="h5"
            >ท่านสามารถนำเข้าข้อมูลที่ผ่านการตรวจสอบได้ทั้งหมด
            <span class="text-success">{{
              dataCorrectList.length | number
            }}</span>
            รายการ</span
          ><br />
          <span class="h5"
            >และไม่สามารถนำเข้าข้อมูลที่ไม่ผ่านการตรวจสอบ
            <span class="text-danger">{{
              dataIncorrectList.length | number
            }}</span>
            รายการ</span
          >
        </div>
        <div *ngIf="isNotAllowSubmitDataHasIncorrect">
          <span class="h5 text-danger"
            >ไม่สามารถนำเข้าข้อมูลที่ไม่ผ่านการตรวจสอบ</span
          >
        </div>
      </div>
    </div>
  </div>

  <!-- Footer -->
  <div class="p-2">
    <div class="row m-1 text-center">
      <div class="col-6">
        <button
          type="button"
          class="btn btn-outline-danger waves-effect waves-themed btn-block"
          (click)="close(false)"
        >
          ปิดหน้านี้
        </button>
      </div>
      <div class="col-6">
        <button
          type="button"
          class="btn btn-success waves-effect waves-themed btn-block"
          [disabled]="dataCorrectList.length <= 0"
          (click)="close(true)"
          *ngIf="!isNotAllowSubmitDataHasIncorrect"
        >
          ยืนยันนำเข้า {{ dataCorrectList.length | number }} รายการ
        </button>
        <button
          type="button"
          class="btn btn-success waves-effect waves-themed btn-block"
          [disabled]="dataIncorrectList.length > 0"
          (click)="close(true)"
          *ngIf="isNotAllowSubmitDataHasIncorrect"
        >
          ยืนยันนำเข้า {{ dataCorrectList.length | number }} รายการ
        </button>
      </div>
    </div>
  </div>
  <!-- Footer -->
</ng-template>
